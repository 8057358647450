import consumer from "./consumer"

consumer.subscriptions.create("UsersChannel", {
  connected() {
  },

  disconnected() {
  },

  received(data) {
    console.log(data)
  }

});
