// This script fetches the images for deduped outputs hover function
document.addEventListener("turbolinks:load", function() {
    $(".thumbnail").each(function() {
        $(this).mouseenter(function() {
            if ($(this).find('img').attr('src') == ''){
                let dedup_id = $(this).data('id'); 
                $(this).find('img').attr('src', 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
                $.getScript('/static/get_url?dedup=' + dedup_id);
            }
        });
    });
});