// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

global.$ = require('jquery')
require("@rails/ujs").start()
require("bootstrap")
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
import "../stylesheets/application"
require("chartkick").use(require("highcharts"))
import Highcharts from 'highcharts';
import addExporting from "highcharts/modules/exporting";
import addBoost from "highcharts/modules/boost";
addExporting(Highcharts)
addBoost(Highcharts)
import Panzoom from '@panzoom/panzoom';
window.Panzoom = Panzoom;
window.Highcharts = Highcharts;
require("packs/highcharts-custom.js")
require("packs/direct_uploads")
require("adminlte")
require("sortable.min")
require("global")
global.resemble = require("resemblejs")
global.Sortable = require("sortablejs")
import moment from 'moment'
window.moment = moment

var ready = function () {
    return $(window).trigger('resize');
};
document.addEventListener('turbolinks:load', ready);
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
